"use client";

import ProjectCard from "../../components/Cards/projectCard";
import Header from "../../components/header";

export default function Products() {
    return (
        <div className="min-h-screen flex flex-col bg-darkseccolor w-full px-12 py-4 space-y-8 ">
            <Header/>
            <h1 className="text-4xl lg:text-6xl font-main text-mcolor fade-in-up">Products</h1>
            <div className="flex-1 space-y-4 fade-in-up">
                <ProjectCard
                    name="Scientific AI Audit Dapp"
                    desc="Powered by Gemini, OpenAI, and Ollama AI Systems, One Click in MAX 5 minutes a comple audit report"
                    proimage="/images/5.jpg"
                    buttonLink="https://github.com/web3decision"
                    buttonText=""
                    is
                />
                <div
                    id="products"
                    className="grid lg:grid-cols-2 gap-4 fade-in-up"
                >
                    <ProjectCard
                        name="Scientific AI KYC Dapp"
                        desc="Powered by Google Pattern Recognition, OpenAI, and Gemini AI Systems, One Click in MAX 10 minutes a comple audit report"
                        proimage="/images/3.jpg"
                        buttonLink="https://github.com/web3decision"
                        buttonText=""
                        is
                    />
                    <ProjectCard
                        name="Scientific CadCad AI Token Economics Dapp"
                        desc="The DApp combines CadCad (a framework for complex systems modeling) with AI to simulate and analyze the tokenomics of decentralized ecosystems. "
                        proimage="/images/2.jpg"
                        buttonLink="https://github.com/web3decision"
                        buttonText=""
                        is
                    />
                    <ProjectCard
                        name="Scientific AI Blockchain Businness Review Dapp"
                        desc="a decentralized application that uses AI to provide in-depth business reviews and analyses for blockchain-based projects."
                        proimage="/images/1.jpg"
                        buttonLink="https://github.com/web3decision"
                        buttonText=""
                        is
                    />
                    <ProjectCard
                        name="Scientific AI Rekt Test Dapp"
                        desc="a decentralized application designed to evaluate the vulnerability and potential risks of blockchain projects, smart contracts, and token ecosystems."
                        proimage="/images/4.jpg"
                        buttonLink="https://github.com/web3decision"
                        buttonText=""
                        is
                    />
                </div>
            </div>
        </div>
    );
}
