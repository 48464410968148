'use client';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

// import { VT323, Viga } from "next/font/google";
import { WagmiConfig, createClient, configureChains } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { bsc } from '@wagmi/core/chains'
import { Toaster } from 'react-hot-toast'
// Connectors
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
const { chains, provider, webSocketProvider } = configureChains(
  [bsc],
  [publicProvider()]
)

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "3314f39613059cb687432d249f1658d2",
        showQrModal: true,
        metadata: {
          name: 'PoolTogether',
          description:
            'Cowfarm is described as a protocol that allows users to place other tokens into a contract for profits.',
          url: 'https://crypto-hunters-projects-crypto-hunters-coin-sales-platform.vercel.app',
          icons: ['https://crypto-hunters-projects-crypto-hunters-coin-sales-platform.vercel.app/assets/logo-79bd39f5.png']
        }
      },
    }),
  ],
  provider,
  webSocketProvider,
})


// const vt323 = VT323({
//   subsets: ["latin"],
//   weight: ["400"],
//   variable: "--font-vt323",
// });

// const viga = Viga({
//   subsets: ["latin"],
//   weight: ["400"],
//   variable: "--font-viga",
// });


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>

      <WagmiConfig client={client}>
        <App />
      </WagmiConfig>
      <Toaster />
      </BrowserRouter>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
