"use client";
import {useEffect, useRef, useState} from "react";
import ConnectionPopup from "./Popup/connectionPopup";
import {useAccount} from 'wagmi'
import {truncateAddress} from '../lib';

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const popupRef = useRef(null);
    const {isConnected, address} = useAccount()
    const truncatedAddress = truncateAddress(address) || undefined;
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const [showConPopup, showConnectionPopup] = useState(false);

    const handleOpenPopup = () => {
        showConnectionPopup(true);  // Opens the popup
    };

    const handleClosePopup = () => {
        showConnectionPopup(false); // Closes the popup
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();

            }
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                handleClosePopup();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <header className="relative z-50 grid items-center grid-cols-3 md:flex md:justify-between">
            {/* Mobile Menu Button */}
            <div className="block md:hidden" >
                <button className="text-mcolor focus:outline-none" onClick={toggleMenu} aria-label="Mobile Menu Button" role="button">
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16m-7 6h7"
                        />
                    </svg>
                </button>
            </div>

            <div className="w-24 md:w-36 justify-self-center md:justify-self-start">
                <a href="/">
                    <img src="./images/logo.png" alt="Logo" height="96" width="96"/>
                </a>
            </div>

            {/* Navigation for Desktop */}
            <nav className="hidden md:block">
                <ul className="flex flex-row space-x-6 text-lg md:text-xl text-mcolor font-main">
                    <li className="cursor-pointer hover:text-darkblue">
                        <a className="hover:text-white hover:shadow-white/20 shadow-lg" href="/">Home</a>
                    </li>
                    <li className="cursor-pointer hover:text-darkblue">
                        <a className="hover:text-white hover:shadow-white/20 shadow-lg" href="/products">Products</a>
                    </li>
                    <li className="cursor-pointer hover:text-darkblue">
                        <a className="hover:text-white hover:shadow-white/20 shadow-lg" href="/stakes">Staking</a>
                    </li>

                    {address ? (
                            <li className="cursor-pointer hover:text-darkblue">
                                <p className="hover:text-neutral-700 hover:shadow-white/20 shadow-lg text-white"
                                >{truncatedAddress}</p>
                            </li>
                        ) :
                        <div>
                            <li className="cursor-pointer hover:text-darkblue">
                                <p className="hover:text-white hover:shadow-white/20 shadow-lg "
                                   onClick={handleOpenPopup}>Wallet
                                    Connection</p>
                            </li>

                        </div>
                    }
                </ul>
            </nav>

            {/* Mobile Menu */}
            <div
                className={`fixed inset-0 bg-black/70 transition-opacity duration-300 ${isMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
            >
                <div
                    ref={menuRef}
                    className={`absolute top-0 right-0 h-full w-40 max-w-xs bg-seccard shadow-lg transform transition-transform duration-500 ease-in-out ${isMenuOpen ? "translate-x-0" : "translate-x-full"
                    }`}
                >
                    <div className="flex flex-col h-full p-6 pt-10 space-y-6 text-lg list-none text-mcolor font-main">
                        {/* Home */}
                        <div className="flex items-center">
                            <a
                                href="/"
                                className="origin-left cursor-pointer hover:text-white"
                                onClick={closeMenu}
                            >
                                Home
                            </a>
                        </div>
                        {/* Products */}
                        <div className="flex items-center">
                            <a
                                href="/products"
                                className="origin-left cursor-pointer hover:text-white"
                                onClick={closeMenu}
                            >
                                Products
                            </a>
                        </div>
                        <div className="flex items-center">
                            <a
                                href="/aboutus"
                                className="origin-left cursor-pointer hover:text-white"
                                onClick={closeMenu}
                            >
                                About Us
                            </a>
                        </div>
                        <div className="flex items-center">
                            <a
                                href="/stakes"
                                className="origin-left cursor-pointer hover:text-white"
                                onClick={closeMenu}
                            >
                                Stakes
                            </a>
                        </div>
                        {/* Connect Wallet */}
                        <div className="flex items-center cursor-pointer" onClick={closeMenu}>
                            <p className="hover:text-white" onClick={handleOpenPopup}>
                                Connect Wallet
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            {showConPopup && <ConnectionPopup popupRef={popupRef} onClose={handleClosePopup}/>}
        </header>
    );
}
