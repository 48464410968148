export default function PaperCard({title, desc, pdfLink}) {
    const isDisabled = !pdfLink;

    return (
        <div
            className="flex flex-col items-center p-6 space-y-6 bg-gradient-to-tl from-seccard to-darkmcolor rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl"
        >
            {/* Paper Details */}
            <div className="flex flex-col items-center justify-center space-y-2 text-center">
                {/* Title */}
                <h1 className="text-xl font-semibold text-white font-main transition-colors duration-300">
                    {title}
                </h1>
                {/* Description */}
                {desc && <p className="text-sm text-gray-300 font-mono">{desc}</p>}
            </div>

            {/* Button */}
            {pdfLink ? (

                <a href={pdfLink}
                   className={`mt-4 px-4 py-2 font-semibold text-darkseccolor hover:text-white  rounded-lg transition-all duration-300 bg-mcolor hover:bg-seccard hover:scale-105 hover:shadow-md`}
                   aria-disabled={isDisabled}
                   target="_blank"
                   rel="noopener noreferrer"
                >
                    Read Paper
                </a>

            ) : (
                <button
                    className={`mt-4 px-4 py-2 font-semibold text-white rounded-lg transition-all duration-300 bg-gray-400 cursor-not-allowed`}

                    disabled={isDisabled}
                >
                    Read Paper
                </button>
            )}
        </div>
    );
}
