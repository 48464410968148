import Header from "../../components/header";

export default function Edit() {
    return (
        <main className="min-h-screen px-4 py-4 space-y-4 bg-darkseccolor">
            <Header/>
            <div className="p-8  bg-introbg rounded-lg space-y-4">
                <h1 className="text-4xl   lg:text-6xl font-main text-mcolor">About Us</h1>
                <p className="text-white font-mono">
                    Web3 Decision is led by a team of industry and academic leaders, including scientists, developers,
                    applied economists, and project managers. <br/>
                    The CEO of Web3 Decision is an Associate Professor with expertise in mathematics, computer science,
                    blockchain, and artificial intelligence. <br/>

                    The Web3 Decision project focuses on integrating artificial intelligence and scientific innovations
                    into the blockchain space, while also creating sustainable earning opportunities for its community.
                    The project leverages its sectoral, scientific, and practical expertise to achieve several key
                    goals, including:<br/>
                </p>
                <ol>
                    <li>
                        <p className="text-white font-mono">
                            1. Strategic Partnerships: Establish partnerships with industry leaders to strengthen the
                            ecosystem.<br/>
                            2. Avalanche Grant Application: Apply for funding through the Avalanche grant program for
                            blockchain integration.<br/>
                            3. Lock DApp: Develop a decentralized tool to lock tokens and safeguard investor funds.<br/>
                            4. AI Audit DApp Development: Begin building an AI-powered audit tool for smart contract
                            security.<br/>
                            5. Binance Labs Grant Application: Apply for funding from Binance Labs to support Web3D's
                            growth and expansion.<br/>
                            6. AI KYC DApp Launch: Roll out an AI-powered Know Your Customer (KYC) app for identity
                            verification.<br/>
                            7. Staking DApp & Program: Introduce a staking platform and program where users can earn
                            rewards based on their Web3D holdings.<br/>
                            8. Token Creation DApp: Create a platform where users can generate their own tokens within
                            the Web3D ecosystem.<br/>
                            9. AI Agents for Market & Trading Strategy: Develop AI agents to assist with market analysis
                            and trading strategies.<br/>
                            11. DePin Integration Tests: Run tests for integrating decentralized physical infrastructure
                            networks (DePin).< br/>
                            12. Web3D Grant Programs: Support developers, influencers, students and content creators
                            through grant programs for innovative solutions and platform promotion.<br/>
                            13. Logic-Based Smart Contracts: Advance smart contracts to enable applications in
                            real-world assets (RWA) and data science.<br/>
                            14. Web3D Research Center: Establish a research center to encourage contributions from
                            undergraduate students.<br/>
                            15. Web3D Charity Foundation: Continue charitable initiatives for community benefit.<br/>
                            16. Web3D Grant Program for Community: Offer grants to community members who actively
                            contribute to the growth and innovation of the Web3D ecosystem.<br/>
                            17. Sony Blockchain Grant Application: Apply for funding from Sony's blockchain grant
                            program to explore innovative use cases in the blockchain space.<br/>
                            18. Cold Wallets Partnership Application: Collaborate with cold wallet providers to ensure
                            enhanced security solutions for Web3D token holders.<br/>
                            19. Ambassador Program: Launch a global ambassador program to recruit and empower
                            representatives who will help grow and promote the Web3D community worldwide.<br/>
                            20. Web3D DEX Start: Launch the Web3D decentralized exchange (DEX) to enable trading within
                            the Web3D ecosystem, enhancing liquidity and user engagement.<br/>
                            21. Global Partnerships: Strengthen ties with global tech companies, blockchain innovators,
                            and financial institutions to broaden Web3D's influence and utility.<br/>
                            22. AI Cadcad DApp: Initiate the development of a simulation tool using AI and cadCAD for
                            advanced financial modeling, helping users optimize complex strategies.<br/>
                            23. University Collaborations: Engage with universities for collaborative research projects
                            and internships, providing students opportunities to contribute to blockchain and AI
                            innovations.<br/>
                            24. AI Rekt DApp: Launch an AI-powered risk management tool designed to assist
                            cryptocurrency traders in managing risks and avoiding potential losses.<br/>
                            25. AI DApp Marketplace: Develop a marketplace dedicated to AI-powered decentralized
                            applications, encouraging the creation and exchange of AI solutions within the Web3D
                            ecosystem.<br/>
                        </p>
                    </li>
                </ol>
            </div>
        </main>
    )
}