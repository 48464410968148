"use client";
import React from "react";
export default function ProjectCard({name, desc, proimage, buttonText, buttonLink}) {
    // Check if buttonLink is provided
    const isDisabled = !buttonLink;

    return (
        <div
            className="flex flex-col justify-between items-center p-6 space-y-6 bg-gradient-to-tl from-seccard to-darkmcolor rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl"
        >
            {/* Project Image */}
            <div className="relative w-24 h-24">
                {/* Background shadow offset */}
                <div
                    className="absolute inset-0 z-0 w-24 h-24 transform translate-x-4 -translate-y-4 bg-darkmcolor rounded-lg"
                ></div>
                {/* Image container */}
                <div
                    className="relative z-10 w-24 h-24 overflow-hidden bg-gray-300 border-2 border-mcolor rounded-lg shadow-md"
                >
                    {proimage ? (
                        <img src={proimage} alt={name} layout="fill" objectFit="cover"/>
                    ) : (
                        <div className="flex items-center justify-center w-full h-full text-gray-500">
                            <svg
                                className="w-12 h-12"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M12 4.5v15m7.5-7.5h-15"
                                />
                            </svg>
                        </div>
                    )}
                </div>
            </div>

            {/* Project Details */}
            <div className="flex flex-col items-center justify-center space-y-2 text-center">
                {/* Project Name */}
                <h1 className="text-xl font-semibold text-white font-main transition-colors duration-300 group-hover:text-mcolor">
                    {name}
                </h1>
                {/* Project Description */}
                {desc && <p className="text-sm text-gray-300 font-mono">{desc}</p>}
            </div>

            {/* Button */}
            {buttonLink ? (

                <a
                    className={`mt-4 px-4 py-2 font-semibold bg-mcolor hover:bg-seccard hover:scale-105 hover:text-white rounded-lg transition-all duration-300`}
                    aria-disabled={isDisabled}
                    href={buttonLink}
                >
                    {buttonText || "Learn More"}
                </a>

            ) : (
                <button
                    className={`mt-4 px-4 py-2 font-semibold text-darkseccolor/40 rounded-lg transition-all duration-300 bg-gray-400 cursor-not-allowed`}
                >
                    {buttonText || "Redirect to Project"}
                </button>
            )}
        </div>
    );
}
