import {useState} from "react";
import DepositPopup from "../Popup/depositPopup";
import WithdrawPopup from "../Popup/withdrawPopup";

export default function StakingCard({
                                        status = "available",
                                        lockPeriod = "2 months",
                                        TVL = "1,091,937.63",
                                        APY = "20%",
                                        depositedAmount = "0 CRH",
                                        rewardAmount = "0.0000 CRH"
                                    }) {
    const [showDepPopup, setShowDepositPopup] = useState(false);
    const [showWithPopup, setShowWithdrawPopup] = useState(false);

    const handleOpenDeptPopup = () => {
        setShowDepositPopup(true); // Opens the popup
    };

    const handleCloseDeptPopup = () => {
        setShowDepositPopup(false); // Closes the popup
    };

    const handleOpenWithPopup = () => {
        setShowWithdrawPopup(true);

    }
    const handleCloseWithdrawPopup = () => {
        setShowWithdrawPopup(false);
    }

    const statusColor = status === "available" ? "bg-green-500" : "bg-red-500";

    return (
        <div
            className="from-seccard bg-gradient-to-b rounded-xl p-6 shadow-lg flex flex-col justify-between w-full h-full">
            {/* Icon and Status */}
            <div className="flex items-center justify-between mb-6">
                <div className="flex items-center">
                    <img src="./images/logo.png" alt="Staking Icon" className="w-14 h-8"/>
                </div>
                <div className="flex items-center space-x-3">
                    <span className="text-white font-semibold capitalize">{status}</span>
                    <span className={`h-3 w-3 ${statusColor} rounded-full`}></span>
                </div>
            </div>

            {/* Staking Info */}
            <div className="text-white space-y-2 flex-1">
                <div className="flex justify-between">
                    <span className="font-main">Lock Period :</span>
                    <span className="font-secondary">{lockPeriod}</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-main">TVL :</span>
                    <span className="font-secondary">{TVL}</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-main">APY :</span>
                    <span className="font-secondary">{APY}</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-main">Your Deposited :</span>
                    <span className="font-secondary">{depositedAmount}</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-main">Your Reward :</span>
                    <span className="font-secondary">{rewardAmount}</span>
                </div>
            </div>

            {/* Buttons */}
            <div className="mt-8 flex flex-col space-y-4">
                <button
                    onClick={handleOpenDeptPopup}
                    className="p-4 flex-1 bg-mcolor text-lg font-mono rounded-md transition-all duration-500 ease-out hover:scale-110 hover:bg-introbg hover:text-mcolor hover:shadow-mcolor shadow-lg animate-ease-out hover:ring-4 ring-mcolor ring-offset-2 ring-opacity-0 hover:ring-opacity-100 hover:animate-pulse hover:ring-offset-mcolor"
                >
                    DEPOSIT
                </button>
                <button
                    onClick={handleOpenWithPopup}
                    className="p-4 flex-1 bg-mcolor text-lg font-mono rounded-md transition-all duration-500 ease-out hover:scale-110 hover:bg-introbg hover:text-mcolor hover:shadow-mcolor shadow-lg animate-ease-out hover:ring-4 ring-mcolor ring-offset-2 ring-opacity-0 hover:ring-opacity-100 hover:animate-pulse hover:ring-offset-mcolor"
                >
                    WITHDRAW
                </button>
            </div>

            {/* Deposit Popup */}
            {showDepPopup && (
                <DepositPopup onClose={handleCloseDeptPopup}/>
            )}

            {showWithPopup && (
                <WithdrawPopup onClose={handleCloseWithdrawPopup}/>
            )}
        </div>
    );
}
