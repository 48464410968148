import logo from './logo.svg';
import './App.css';
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Edit, Home, Papers, Stakes } from './pages';
import Products from './pages/products';
window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/home" />} />
      <Route
        path="/home"
        element={
          <>
            <Home />
          </>
        }
      />
       <Route
        path="/aboutus"
        element={
          <>
            <Edit />
          </>
        }
      />
      <Route
        path="/stakes"
        element={
          <>
            <Stakes />
          </>
        }
      />
      <Route
        path="/papers"
        element={
          <>
            <Papers />
          </>
        }
      />
      <Route
        path="/products"
        element={
          <>
            <Products />
          </>
        }
      />
    </Routes>
  );
}

export default App;
