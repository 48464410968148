import {useEffect, useRef, useState} from "react";

export default function WithdrawPopup({onClose}) {
    const [amount, setAmount] = useState("");
    const [error, setError] = useState(""); // State for error messages
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [onClose]);

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
        setError(""); // Clear error when user starts typing
    };

    const handleMaxClick = () => {
        setAmount("0"); // Example value; replace with actual balance
        setError(""); // Clear error when setting max value
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const parsedAmount = parseFloat(amount);
        if (isNaN(parsedAmount) || parsedAmount <= 0) {
            setError("Please enter a valid amount.");
            return;
        }
        if (parsedAmount > 0) { // Replace with actual balance check if needed
            console.log("Depositing:", amount);
            onClose(); // Close popup after submission
        } else {
            setError("Amount must be greater than zero.");
        }
    };

    return (
        <div role="dialog" aria-label="Withdraw Popup"
             className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center overflow-y-auto">
            <div
                className="bg-darkseccolor p-6 md:p-8 rounded-xl shadow-2xl relative w-full max-w-sm md:max-w-lg h-auto"
                ref={popupRef}
            >
                <h2 className="text-xl md:text-2xl font-bold mb-6 md:mb-8 text-center text-mcolor font-mono">
                    Withdraw
                </h2>

                <div className="text-white mb-4">
                    <p className="mb-2">Your Balance: 0 web3</p>
                    {error && (
                        <div className="mb-4 p-2 bg-red-600 text-white rounded-md text-center">
                            {error}
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                            <input
                                type="number"
                                value={amount}
                                onChange={handleAmountChange}
                                className="p-4 rounded-md border border-darkmcolor bg-seccard text-mcolor flex-1 focus:outline-none focus:ring-2 focus:ring-mcolor"
                                placeholder="Enter amount"
                            />
                            <button
                                type="button"
                                onClick={handleMaxClick}
                                className="mt-4 w-full md:w-auto p-4 bg-mcolor text-lg text-darkseccolor font-mono rounded-md transition-all duration-500 ease-out hover:scale-110 hover:bg-introbg hover:text-mcolor hover:shadow-mcolor shadow-lg animate-ease-out hover:ring-4 ring-mcolor ring-offset-2 ring-opacity-0 hover:ring-opacity-100 hover:animate-pulse hover:ring-offset-mcolor"
                            >
                                Max
                            </button>
                        </div>
                        <button
                            type="submit"
                            className="w-full mt-4 p-4 bg-mcolor text-lg text-darkseccolor font-mono rounded-md transition-all duration-500 ease-out hover:scale-110 hover:bg-introbg hover:text-mcolor hover:shadow-mcolor shadow-lg animate-ease-out hover:ring-4 ring-mcolor ring-offset-2 ring-opacity-0 hover:ring-opacity-100 hover:animate-pulse hover:ring-offset-mcolor"
                        >
                            Submit
                        </button>
                    </form>
                </div>

                {/* Close Button */}
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-gray-400 hover:text-mcolor transition-colors duration-300 ease-in-out"
                >
                    &#10005;
                </button>
            </div>
        </div>
    );
}
