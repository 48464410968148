"use client";

import Header from "../../components/header";
import StakingCard from "../../components/Cards/stackingCard";

export default function Stakes() {


    return (
        <div className="min-h-screen flex flex-col bg-darkseccolor w-full px-12 py-4 space-y-8">
            <Header/>
            <h1 className="text-4xl lg:text-6xl font-main text-mcolor fade-in-up">Coming Soon</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-center w-full">
                <StakingCard
                    status="available"
                    lockPeriod="1 months"
                    TVL="500,000.00"
                    APY="15%"
                    depositedAmount="50 WEB3D"
                    rewardAmount="5.0000 WEB3D"
                />
                <StakingCard
                    status="available"
                    lockPeriod="2 months"
                    TVL="500,000.00"
                    APY="15%"
                    depositedAmount="50 WEB3D"
                    rewardAmount="5.0000 WEB3D"
                />
                <StakingCard
                    status="available"
                    lockPeriod="3 months"
                    TVL="500,000.00"
                    APY="15%"
                    depositedAmount="50 WEB3D"
                    rewardAmount="5.0000 WEB3D"
                />
                  <StakingCard
                    status="available"
                    lockPeriod="9 months"
                    TVL="500,000.00"
                    APY="15%"
                    depositedAmount="50 WEB3D"
                    rewardAmount="5.0000 WEB3D"
                />
                  <StakingCard
                    status="available"
                    lockPeriod="12 months"
                    TVL="500,000.00"
                    APY="15%"
                    depositedAmount="50 WEB3D"
                    rewardAmount="5.0000 WEB3D"
                />
                  <StakingCard
                    status="available"
                    lockPeriod="12 months"
                    TVL="500,000.00"
                    APY="15%"
                    depositedAmount="50 WEB3D"
                    rewardAmount="5.0000 WEB3D"
                />
            </div>
        </div>
    );
}
