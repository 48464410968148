"use client";
import React from "react";


import ReleaseCard from "./Cards/releaseCard";
import SocialMedia from "./socialmedia";
import {useNavigate} from 'react-router-dom';

export default function Intro() {
    const navigate = useNavigate();
    return (
        <div className="lg:h-[80vh]  w-full flex flex-col  space-y-12 fade-in-up" aria-label="Intro Section">
            {/* Top Section */}
            <div className="flex flex-col lg:space-x-4 space-y-4 lg:space-y-0  flex-1 w-full ">
                {/* Info */}
                <div className="flex flex-col lg:flex-row flex-grow space-y-4 lg:space-x-4 lg:space-y-0 w-full">
                    {/* Left */}
                    <div
                        className="relative lg:flex-1 lg:h-full bg-gradient-to-b from-introbg/60 p-8 rounded-lg overflow-hidden content-center">
                        <div
                            className="bg-gradient-to-b from-seccard to-introbg/60 absolute inset-0 w-full h-full z-10"/>
                        <video autoPlay muted loop className="absolute inset-0 w-full h-full object-cover rounded-lg">
                            <source src="/videos/introbg.mp4" type="video/mp4"/>
                        </video>

                        <div
                            className="absolute inset-0 bg-gradient-to-b from-black/60 via-transparent to-black/60 rounded-lg"></div>

                        <div className="relative z-10 items-center lg:items-start flex flex-col space-y-8">
                            <div className="font-secondary">
                                <h1 className="text-3xl md:text-4xl lg:text-6xl text-mcolor text-center lg:text-left place-content-center lg:place-content-start flex gap-4 pb-4">
                                    <div
                                        className="w-4 h-6 lg:h-9 bg-gradient-to-b from-mcolor self-center rounded-2xl"></div>
                                    Web3 Quality
                                </h1>
                                <div className="text-white text-center lg:text-left">
                                    <h2 className="text-2xl md:text-4xl lg:text-6xl">Solutions All Needs</h2>
                                    <h2 className="text-3xl md:text-4xl lg:text-6xl">on One D-Center</h2>
                                </div>
                            </div>
                            <div className="flex space-x-4 font-mono">
                                <a aria-label="About Us Page for Information About Web3 Decision"
                                   className="bg-mcolor text-xl rounded-md transition-all duration-500 ease-out hover:scale-110 hover:bg-introbg hover:text-mcolor hover:shadow-mcolor shadow-lg hover:ring-4 ring-mcolor ring-offset-2 ring-opacity-0 hover:ring-opacity-100 hover:ring-offset-mcolor text-center w-30 lg:w-40 p-2"
                                   href="/aboutus"
                                >
                                    About Us
                                </a>
                                <a aria-label="Papers Page for Project Details"
                                   className="bg-mcolor text-xl rounded-md transition-all duration-500 ease-out hover:scale-110 hover:bg-introbg hover:text-mcolor hover:shadow-mcolor shadow-lg hover:ring-4 ring-mcolor ring-offset-2 ring-opacity-0 hover:ring-opacity-100 hover:ring-offset-mcolor text-center w-30 lg:w-40 p-2"
                                   href="/papers"
                                >
                                    Papers
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Right */}
                    <div className="lg:w-1/3 xl:w-1/3 lg:flex lg:flex-col space-y-4">
                        {/* Introduce */}
                        <div
                            className="flex-1 from-mcolor/20 lg:from-mcolor/40 bg-gradient-to-b p-4 rounded-lg content-center">
                            <div className="space-y-1 lg:space-y-4 text-center">
                                <h1 className="text-xl lg:text-4xl xl:text-4xl text-mcolor font-secondary">
                                    Proudly Published by
                                </h1>
                                <a aria-label="PhD. Selçuk Topal's Gebze Technic University Profile Web Page"
                                   href="https://www.gtu.edu.tr/en/personel/573/59348532/display.aspx"
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <h2 className="text-2xl lg:text-4xl xl:text-3xl font-main text-white">
                                        PhD. Selçuk Topal
                                    </h2>
                                </a>
                            </div>
                        </div>
                        {/* Release Channel */}
                        <ReleaseCard/>
                        {/* Social Media */}
                        <SocialMedia/>
                    </div>
                </div>
                {/* Products Quick Actions */}
                {/* Add content here or remove if unnecessary */}
            </div>
            <div className="rounded-lg grid grid-cols-1 lg:grid-cols-4 w-full gap-4  font-secondary">
                <button
                    className="p-4 w-auto bg-mcolor text-xl rounded-md transition-all duration-500 ease-out hover:scale-110 hover:bg-introbg hover:text-mcolor hover:shadow-mcolor shadow-lg animate-ease-out hover:ring-4 ring-mcolor ring-offset-2 ring-opacity-0 hover:ring-opacity-100 hover:animate-pulse hover:ring-offset-mcolor">
                    AI Audit
                </button>
                <button
                    className="p-4 w-auto bg-mcolor text-xl rounded-md transition-all duration-500 ease-out hover:scale-110 hover:bg-introbg hover:text-mcolor hover:shadow-mcolor shadow-lg animate-ease-out hover:ring-4 ring-mcolor ring-offset-2 ring-opacity-0 hover:ring-opacity-100 hover:animate-pulse hover:ring-offset-mcolor">
                    AI KYC
                </button>
                <button
                    className="p-4 w-auto bg-mcolor text-xl rounded-md transition-all duration-500 ease-out hover:scale-110 hover:bg-introbg hover:text-mcolor hover:shadow-mcolor shadow-lg animate-ease-out hover:ring-4 ring-mcolor ring-offset-2 ring-opacity-0 hover:ring-opacity-100 hover:animate-pulse hover:ring-offset-mcolor">
                    Token Creation
                </button>
                <button
                    onClick={() => navigate('/stakes')}
                    className="p-4 w-auto bg-mcolor text-xl rounded-md transition-all duration-500 ease-out hover:scale-110 hover:bg-introbg hover:text-mcolor hover:shadow-mcolor shadow-lg animate-ease-out hover:ring-4 ring-mcolor ring-offset-2 ring-opacity-0 hover:ring-opacity-100 hover:animate-pulse hover:ring-offset-mcolor">
                    Stakes
                </button>


            </div>

        </div>
    );
}
