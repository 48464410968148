import {
    PiGithubLogoFill,
    PiInstagramLogoFill,
    PiMediumLogoFill,
    PiRedditLogoFill,
    PiTelegramLogoFill,
    PiXLogoFill,
} from "react-icons/pi";

export default function SocialMedia() {
    return (
        <div className="flex flex-col gap-4 justify-items-center pt-2">

            <div className="flex space-x-4">

                {/* Telegram */}
                <a  aria-label="Telegram Link for Web3 Decision"
                    href="https://t.me/web3decisioncom"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center flex-1 w-20 h-20 text-4xl text-white transition-all duration-300 transform rounded-lg ring-2 ring-mcolor/70 bg-gradient-to-br from-mcolor to-seccard hover:text-white hover:bg-gradient-to-br hover:from-seccard hover:to-mcolor hover:scale-110 hover:ring-seccard"
                >
                    <PiTelegramLogoFill/>
                </a>

                {/* Github */}
                <a
                    aria-label="Github Link for Web3 Decision"
                    href="https://github.com/web3decision"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center flex-1 w-20 h-20 text-4xl text-white transition-all duration-300 transform rounded-lg ring-2 ring-mcolor/70 bg-gradient-to-br from-mcolor to-seccard hover:text-white hover:bg-gradient-to-br hover:from-seccard hover:to-mcolor hover:scale-110 hover:ring-seccard"
                >
                    <PiGithubLogoFill/>
                </a>

                {/* Medium */}
                <a
                    aria-label="Medium Link for Web3 Decision"
                    href="https://medium.com/@web3decision"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center flex-1 w-20 h-20 text-4xl text-white transition-all duration-300 transform rounded-lg ring-2 ring-mcolor/70 bg-gradient-to-br from-mcolor to-seccard hover:text-white hover:bg-gradient-to-br hover:from-seccard hover:to-mcolor hover:scale-110 hover:ring-seccard"
                >
                    <PiMediumLogoFill/>
                </a>
            </div>
            <div className="flex space-x-4 ">
                {/* X (Twitter) */}
                <a
                    aria-label="Twitter Link for Web3 Decision"
                    href="https://x.com/web3decision"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center flex-1 w-20 h-20 text-4xl text-white transition-all duration-300 transform rounded-lg ring-2 ring-mcolor/70 bg-gradient-to-br from-mcolor to-seccard hover:text-white hover:bg-gradient-to-br hover:from-seccard hover:to-mcolor hover:scale-110 hover:ring-seccard"
                >
                    <PiXLogoFill/>
                </a>
                <a
                    aria-label="Instagram Link for Web3 Decision"
                    href="https://www.instagram.com/web3decision/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center flex-1 w-20 h-20 text-4xl text-white transition-all duration-300 transform rounded-lg ring-2 ring-mcolor/70 bg-gradient-to-br from-mcolor to-seccard hover:text-white hover:bg-gradient-to-br hover:from-seccard hover:to-mcolor hover:scale-110 hover:ring-seccard"
                >
                    <PiInstagramLogoFill/>
                </a>
                <a
                    aria-label="Reddit Link for Web3 Decision"
                    href="https://www.reddit.com/user/web3decision/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center flex-1 w-20 h-20 text-4xl text-white transition-all duration-300 transform rounded-lg ring-2 ring-mcolor/70 bg-gradient-to-br from-mcolor to-seccard hover:text-white hover:bg-gradient-to-br hover:from-seccard hover:to-mcolor hover:scale-110 hover:ring-seccard"
                >
                    <PiRedditLogoFill/>
                </a>
            </div>

        </div>
    );
}
