import Header from "../../components/header";
import Intro from "../../components/intro";
import React from "react";

export default function Home() {
    return (
        <main className="min-h-screen px-12 py-4 space-y-4   bg-darkseccolor">
            <Header/>
            <Intro id="home"/>
        </main>
    );
}
