import {useState} from "react";
import {WalletCoinbase, WalletMetamask, WalletWalletConnect} from "@web3icons/react";
import {useConnect} from 'wagmi'
import {toast} from 'react-hot-toast'

export default function ConnectionPopup({onClose, popupRef}) {
    const [error, setError] = useState(""); // State for error messages
    const {connect, connectors, isLoading, pendingConnector} = useConnect({
        onSuccess: () => {
            toast.success('Wallet connected successfully!')
            onClose()
        },
        onError: () => {
            toast.error('Something went wrong!')
        },
    })

    const handleWalletConnect = (walletType) => {
        try {
            // Replace this with actual connection logic
            console.log(`Connecting with ${walletType}`);
            // Simulate a potential error
            throw new Error("Connection failed"); // Example error
        } catch (err) {
            setError(err.message); // Set the error message
        }
    };

    return (
        <div role="dialog" aria-label="Connection Popup"
             className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center overflow-y-auto">
            <div
                className="bg-darkseccolor p-6 md:p-8 rounded-xl shadow-2xl relative w-full max-w-sm md:max-w-lg h-auto"
                ref={popupRef}
            >
                <h2 className="text-xl md:text-2xl font-bold mb-6 md:mb-8 text-center text-mcolor font-mono">
                    Connect Wallet
                </h2>

                {error && (
                    <div className="mb-4 p-2 bg-red-600 text-white rounded-md text-center">
                        {error}
                    </div>
                )}

                <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 justify-center">
                    {/* Wallet Option 1 */}

                    {connectors.map((connector, index) => (
                        <div
                            key={connector.id}
                            className="group text-lg md:text-xl flex items-center justify-center flex-col px-4 py-4 shadow-md rounded-lg border-2 border-transparent hover:border-darkseccolor cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105 from-mcolor bg-gradient-to-b"
                            onClick={() => connect({connector})}
                            disabled={!connector.ready}
                        >
                            {index == 0 &&
                                <WalletMetamask size={"40px"} className="mt-4 group-hover:text-darkseccolor"/>}
                            {index == 1 &&
                                <WalletCoinbase size={"40px"} className="mt-4 group-hover:text-darkseccolor"/>
                            }
                            {index == 2 &&
                                <WalletWalletConnect size={"40px"} className="mt-4 group-hover:text-darkseccolor"/>
                            }
                            <p className="text-mcolor mt-2 group-hover:text-white font-main">{connector.name}</p>

                        </div>
                    ))}
                </div>

                {/* Close Button */}
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-gray-400 hover:text-mcolor transition-colors duration-300 ease-in-out"
                >
                    &#10005;
                </button>
            </div>
        </div>
    );
}
