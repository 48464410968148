export default function ReleaseCard() {
    return (
        <div
            className="lg:h-1/5 bg-gradient-to-t from-releasebg/20 p-2 rounded-lg text-center">

            <div className="space-y-2">
                <a href="/products" className="font-main text-mcolor text-xl animate-pulse">
                    Latest Release
                </a>


                <a href="https://github.com/web3decisioncom/introductions/blob/main/Web3D%20WhitePaper%20V1.pdf"
                   target="_blank" rel="noopener noreferrer">
                    <h1 className="font-secondary text-white  text-xl">
                        White Paper V1.0
                    </h1>
                </a>

            </div>

        </div>
    )
}