import PaperCard from "../../components/Cards/paperCard";
import Header from "../../components/header";

export default function Papers() {
    return (
        <div className="min-h-screen flex flex-col bg-darkseccolor w-full px-12 py-4 space-y-8 ">
            <Header/>
            <div className="flex flex-col space-y-4  lg:px-10"><PaperCard
                title="Web3D Pitch Deck"
                desc="A comprehensive pitch deck September 2024 guide to building applications with Web3Decison."
                pdfLink="pdf/PitchDeck.pdf"
            />

                <PaperCard
                    title="Web3D Token Holders Benefits"
                    desc="A comprehensive Web3D Token Holders Benefits guide to benefits with Web3Decison."
                    pdfLink="pdf/Earnings-Token-Holders.pdf"
                />


                <PaperCard
                    title="Web3D Token Sahiplerinin Avantajları"
                    desc="Web3Decison ile avantajlara yönelik kapsamlı bir Web3D Token Sahiplerinin Avantajları kılavuzu"
                    pdfLink="pdf/Token-Sahiplerinin-Kazanclari.pdf"
                />

                <PaperCard
                    title="Platform Customer Benefits"
                    desc="A comprehensive Platform Customer Benefits guide to building future with Web3Decison."
                    pdfLink="pdf/Customer-Benefits.pdf"
                />

                <PaperCard
                    title="Platform Müşterilerinin Kazançları"
                    desc="Web3Decison ile geleceği inşa etmeye yönelik kapsamlı bir Platform Müşteri Avantajları kılavuzu"
                    pdfLink="pdf/Musteri-Kazanclari.pdf"
                />

                <PaperCard
                    title="Bob's Stake Income Example"
                    desc="A comprehensive Bob's Stake Income Example guide to earnings with Web3Decison."
                    pdfLink="pdf/Bob-Stake-Income.pdf"
                />

                <PaperCard
                    title="Recep'in Stake Kazanç Örneği"
                    desc="Web3Decison ile kazançlara yönelik kapsamlı bir Recep'in Stake Gelir Örneği kılavuzu."
                    pdfLink="pdf/Recep-Stake.pdf"
                />


            </div>

        </div>
    )
}
